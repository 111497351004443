@font-face{
  font-family:"gothicBold";
  src: url("./webfonts/ITCAvantGardeStd-Bold.woff")
}
.Name{
  font-family:"gothicBold";
  color: darkslategray
}
.Navbar-body{
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  padding: 0.5rem 0px;
  width:100%
}
.Nav-links{
  padding:10px;
}
.Selected-nav-links{
  padding:10px;
  border-radius: 20px;
  background-color:lavenderblush
}
.Nav-container{
  display:flex;
  justify-content:center
}



@media (max-width:1600px){
  .Navbar-body{
    display: flex;
    flex-direction: column;
  }

}
.substack{
  position: relative;
  transform: scale(.85);
  right: 4px;
}

@media (max-width:1025px){
.substack{
  transform: scale(.60);
  position: relative;
  align-items:center
}
.Icon-container{
  align-items:center
}
}
@media (max-width:951px){
  .Icon-container{
    padding-bottom:20px;
    align-items: baseline;
  }
  .substack{
    transform: scale(.50);
    position: relative;
    top: 9px;
    right: 10px;
  }
}


@media (max-width:768px){
  .substack{
    transform: scale(.5);
    position: relative;
  }
}
@media (max-width:376px){
  .substack{
    transform: scale(.4);
    position: relative;
    top: 11px;
  }
}

.Logos{
  color:pink;
  padding:5px;
}

.Left-navbar{
  display:flex;
  flex-direction:row;
  align-items:center
}

.Icon-container{
  padding-left:5px;
  display:flex;
}


