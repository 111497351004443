.Email-container{
  position: relative;
    border-bottom: 1px solid pink;
    display:flex;
}
.Form-container{
display: flex;
    justify-content: center;
    padding-bottom: 1.5em;
  }
.Email-input{
  border:none;
}

.Past-issues{
  color:slategray;
  padding-bottom:10px;
  font-size:16px;
  text-decoration: underline;
}

.Subscribe{
  font-size: x-large;
  padding-bottom: 10px;
  color:slategray
}

.Submit{
  align-self:center
}
