@font-face{
  font-family:"charter";
  src: url("./webfonts/charter_regular-webfont.woff")
}
@font-face{
  font-family:"gothic";
  src: url("./webfonts/ITCAvantGardeStd-Bk.woff")
}
@font-face{
  font-family:"gothic-bold";
  src: url("./webfonts/ITCAvantGardeStd-Bold.woff")
}
.Contact-form-container{
  padding-bottom:80px
}

.Contact-email-container{
  position: relative;
    margin-bottom: 0;
    padding-bottom: .5em;
    border-bottom: 1px solid pink;
    display:flex;
}
.Email-input{
  border:none;
  width:100%
}
.Email-input:focus{
  outline:none
}

.Message-container{
  position: relative;
  margin-bottom: 1em;
  padding-bottom: .5em;
  padding-top: 1em;
  border:none;
  border-bottom: 1px solid pink;
  width:30em;
  height:5em
}
.Name-container{
  position: relative;
  margin-bottom: 1em;
  padding-bottom: .5em;
  border:none;
  border-bottom: 1px solid pink;
  width:30em;
}
.Name-input{
  border:none;
  width:100%
}
.Name-input:focus{
  outline:none
}
.Submit{
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 10px 15px;
  font-size: 1em;
  line-height: 1.42857143;
  border-radius: 4px;
  color: #ffffff;
    background-color: #F4C2C2;
    border-color: #F4C2C2;
      font-family:"gothic"
}

.Message-container:focus{
  outline:none;
}
textarea{
  font-family:"gothic";
  font-size: 1em;
}

input{
  font-family:"gothic";
  font-size: 1em;
}
@media (max-width:768px){
  .Message-container{
    width:20em;
  }
  .Name-container{
    width:20em;
  }
  .Contact-email-container{
    width:20em
  }
}

.Contact{
    font-size: x-large;
    padding-bottom: 20px;
    color:slategray
}

textarea::placeholder{
  font-family:"gothic";
  font-size: 1em;
}

input::placeholder{
  font-family:"gothic";
  font-size: 1em;
}
