.Test-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px;
  color:slategray
}

.Testimonial-p{
  display: flex;
  font-size:larger;
  font-family:"gothic"
}

.Testimonial-a{
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
    /* font-family:"gothic-bold" */
}

.Test-body{
  width:50%;
  padding-bottom: 80px;
}

@media (max-width:896px){
  .Test-body{
    width:90%;
    padding-bottom: 80px;
  }
}

.Keywords{
  font-size: x-large;
  padding-bottom: 20px;
  color:slategray
}

.Scroll-to-top{
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  color: #ffffff;
    background-color: #F4C2C2;
    border-color: #F4C2C2;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 24px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
