.Button{
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 10px 15px;
  font-size: 1em;
  line-height: 1.42857143;
  border-radius: 4px;
  color: #ffffff;
    background-color: #F4C2C2;
    border-color: #F4C2C2;
      font-family:"gothic"
}
