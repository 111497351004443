.Header{
  font-size: x-large;
  padding-bottom: 40px;
  color:slategray
}
.Studio-container{
  display:flex;
  flex-direction:column;
  padding-bottom:80px
}
.Studio-items{
  font-size:large;
  color:slategray;
  padding-bottom:5%
}

