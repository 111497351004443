.Paragraph{
  font-size: 20px;
  display:flex;
  flex-direction:column;
  color:slategray;
  margin:10px
}
.About-body{
  width:50%;
  display:flex;
  flex-direction: column;
  padding-bottom: 80px;
}



.About-header{
  font-size: x-large;
  padding-bottom: 20px;
  color:slategray
}
span{
  font-style:italic;
  display:contents
}

.Image-container{
  display: flex;
    justify-content: space-evenly;
    padding-bottom:20px;
}
.Image{
  height:auto;
  width:25%
}
.Paragraph-container{
  padding-bottom:20px
}
@media (max-width:1400px){
  .About-body{
    width:90%;
    display:flex;
    flex-direction: column;
    padding-bottom: 80px;
  }
}

@media (max-width:896px){
  .Image-container{
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    width: 80%;
    align-self: center;
  }
  .Image{
    height:auto;
    width:100%;
    margin-bottom:10px
  }
  .About-body{
    width:90%;
    display:flex;
    flex-direction: column;
    padding-bottom: 80px;
  }

}


