@font-face{
  font-family:"charter";
  src: url("./webfonts/charter_regular-webfont.woff")
}
@font-face{
  font-family:"gothic";
  src: url("./webfonts/ITCAvantGardeStd-Bk.woff")
}
@font-face{
  font-family:"gothic-bold";
  src: url("./webfonts/ITCAvantGardeStd-Bold.woff")
}

.App {
  text-align: center;
  font-family:"gothic"
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.App-body{
  display: flex;
  width: 100%;
  justify-content: center;
}

.App-header {
  background-color: #FFFFFF;
  padding: 0px 10em;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

a{
  font-size: calc(5px + 2vmin);
  font-weight: 350;
  color: pink;
  text-decoration: none;
}
